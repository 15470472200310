import React from "react";
import voucherStyles from "../styles/voucherStyles.module.css";

const OfferVoucher = () => {
  const title = "Voucher";
  const subtitle = "Podaruj swoim najbliższym wyjątkowy prezent";
  const textOne =
    "To ekskluzywna niespodzianka, która uszczęśliwi obdarowaną przez Ciebie osobę. Voucher może być formą odpoczynku dla osoby obdarowanej, ale także wyróżnieniem i uznaniem dla pracownika oraz zmotywowania i podziękowania mu za owocną pracę.";
  const textTwo =
    "To Ty ustalasz zabieg lub wysokość kwoty, za którą osoba obdarowana może skorzystać z naszej oferty";
  const vouchers = [
    {
      title: "Voucher na określoną kwotę",
      text: "Ten rodzaj voucheru możesz podarować osobie, której chcesz sprawić prezent, ale nie wiesz jaki zabieg wybrać. Voucher opiewa na wybraną przez Ciebie kwotę. Osoba, która otrzyma Voucher może wykorzystać go na wybrane przez siebie zabiegi.",
    },
    {
      title: "Voucher na określoną usługę",
      text: "Ten rodzaj voucheru możesz podarować osobie, której chcesz sprawić niespodziankę i wiesz jaki zabieg wybrać. Voucher opiewa na wybraną przez Ciebie usługę lub usługi.",
    },
  ];
  const textThree = "Zasady sprzedaży voucherów upominkowych";
  const textFour = "Wygląd karty podarunkowej";
  const zasady = [
    "Vouchery są wydawane w formie eleganckiego zaproszenia zapakowanego w kopertę.",
    "Vouchery dostępne są w ciągłej sprzedaży i mogą opiewać na dowolną kwotę.",
    "Vouchery na kwotę mają formę zaproszenia imiennego. Można nabyć również zaproszenia bezimienne na wybrane usługi lub pakiety usług.",
  ];
  // const voucherMain = require("../images/voucher-main.jpg");
  const voucherMain = require("../images/image.png");
  const voucher = require("../images/voucher.png");
  // const voucherTwo = require("../images/money-voucher.jpg");
  // const voucherThree = require("../images/voucher-facial.jpeg");
  // const voucherEnvelope = require("../images/voucher-envelope.jpeg");
  // const voucherFour = require("../images/voucher-two.jpg");
  // const voucherIdentity = require("../images/voucher-identity.jpg");

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyItems: "space-between",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          padding: "80px 13% 60px 13%",
        }}
      >
        <div
          style={{
            width: "30%",
            height: "350px",
            minWidth: "min(350px, 80%)",
            flexGrow: 1,
          }}
        >
          <img
            src={voucherMain}
            style={{ width: "100%", objectFit: "cover", height: "100%" }}
          />
        </div>
        <div
          style={{
            textAlign: "left",
            width: "35%",
            marginBottom: "50px",
            marginLeft: "min(50px, 5%)",
            flexGrow: 1,
          }}
        >
          <h1 className={voucherStyles.h1}>{title}</h1>
          <h4 className={voucherStyles.h4}>{subtitle}</h4>
          <p className={voucherStyles.p}>{textOne}</p>
        </div>
      </div>
      <div style={{ padding: "30px max(60px, 10%) 0 max(60px, 10%)" }}>
        <h2 className={voucherStyles.h2}>{textTwo}</h2>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
          padding: "70px 10%",
        }}
      >
        {vouchers.map((voucher, i) => (
          <div
            key={i}
            style={{
              width: "45%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              minWidth: "max(250px, 45%)",
              marginBottom: "50px",
              flexGrow: 1,
              marginRight: "min(20px, 5%)",
            }}
          >
            {/* <div
              style={{
                width: "75%",
                height: "250px",
                marginBottom: "20px",
              }}
            > */}
              {/* <img
                style={{ width: "100%", objectFit: "cover", height: "100%" }}
                src={i === 0 ? voucherTwo : voucherThree}
              /> */}
            {/* </div> */}
            <h3 className={voucherStyles.h3}>{voucher.title}</h3>
            <p className={voucherStyles.p} style={{ textAlign: "center" }}>
              {voucher.text}
            </p>
          </div>
        ))}
      </div>
      <div style={{ padding: "0 10% 70px 10%" }}>
        <h2 className={voucherStyles.h2} style={{ marginBottom: "70px" }}>
          {textThree}
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {zasady.map((voucher, i) => (
            <div
              key={i}
              style={{
                width: "30%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                minWidth: "max(250px, 30%)",
                marginBottom: "20px",
                marginRight: "min(20px, 3%)",
                flexGrow: 1,
              }}
            >
              {/* <div
                style={{
                  width: "80%",
                  height: "250px",
                  backgroundColor: "grey",
                }}
              > */}
                {/* <img
                  style={{ width: "100%", objectFit: "cover", height: "100%" }}
                  src={
                    i === 0
                      ? voucherEnvelope
                      : i === 1
                      ? voucherFour
                      : voucherIdentity
                  }
                /> */}
              {/* </div> */}
              <h4 className={voucherStyles.h5}>{voucher}</h4>
            </div>
          ))}
        </div>
        <div style={{marginTop: "80px"}}>
          <h2 className={voucherStyles.h2}>{textFour}</h2>
          <img
            src={voucher}
            alt="Lumio Skin voucher image"
            style={{
              width: "100%",
              marginTop: "60px",
              maxWidth: "450px",
            }}
          />
        </div>
        <div style={{ marginBottom: "60px" }}>
          <p style={{ fontSize: "12px" }}>
            Sprawdź warunki korzystania z voucherów w{" "}
            <a
              style={{ color: "black" }}
              href={require("../images/LumioSkin-Regulamin-Voucher.pdf")}
              target="_blank"
              rel="noopener noreferrer"
            >
              Regulaminie
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default OfferVoucher;
