import React, { useState, useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import offerFacialStyles from "../styles/offerFacialStyles.module.css";
import { useWindowSize } from "../components/Navbar";
import ScrollSpy from "react-ui-scrollspy";

const OfferFacial = () => {
  const size = useWindowSize();
  const [selectedFacial, setSelectedFacial] = useState(0);
  // const placeholderImageOne = require("../images/offerFacialPlaceholderOne.jpg");
  // const placeholderImageTwo = require("../images/offerFacialPlaceholderTwo.jpg");
  // const placeholderImageThree = require("../images/offerFacialPlaceholderThree.jpg");
  // const placeholderImageFour = require("../images/offerFacialPlaceholderFour.jpg");
  // const placeholderImageSix = require("../images/offerFacialPlaceholderSix.jpg");
  // const placeholderImageSeven = require("../images/offerFacialPlaceholderSeven.jpg");
  const additions = [
    "Poprawa jakości skóry",
    "Z myślą o Tobie",
    "Długotrwałe rezultaty",
  ];
  const facials = [
    {
      name: "Mezoterapia igłowa",
      // image: placeholderImageOne,
      subtitle: null,
      desc: `Mezoterapia Igłowa skutecznie odżywia skórę, pomaga zlikwidować najbardziej widoczne objawy
          przemęczenia i starzejącej się skóry.
          Skóra po zabiegu staje się wyraźnie odmłodzona, bardziej sprężysta i jędrna. Znikają zasinienia wokół
          oczu, a także zmarszczki i obrzęki.
          Mezoterapia Igłowa polega na iniekcyjnym aplikowaniu specjalistycznych preparatów w głąb skóry.
          Przed zabiegiem nakładane jest znieczulenie w postaci kremu.
          Podczas zabiegu powstają mikropęcherzyki z odpowiednio ulokowanym preparatem. Większość grudek
          znika w ciągu pierwszej doby.`,
    },
    {
      name: "Mezoterapia mikroigłowa",
      // image: placeholderImageTwo,
      subtitle: null,
      desc: `Podczas zabiegu usuwana jest wierzchnia warstwa skóry oraz tworzone są mikrokanaliki, dzięki którym
          znacznie lepiej wprowadzane są odpowiednio dobrane preparaty.
          Mezoterapia mikroigłowa pomaga w regeneracji skóry, stymulując naturalne procesy naprawcze.
          Synteza kolagenu i elastyny prowadzi do poprawy napięcia skóry, nadając jej młodszy i bardziej jędrny
          wygląd.
          Podczas zabiegu odczuwalny jest minimalny dyskomfort, a czas rekonwalescencji jest krótki. Po zabiegu
          skóra jest czerwona i podrażniona.`,
    },
    {
      name: "Mezoterapia nanoigłowa",
      // image: placeholderImageThree,
      subtitle: null,
      desc: `Efekty po zabiegu widoczne są od razu w postaci delikatnego napięcia skóry, wyrównania
          powierzchownych zmarszczek, wyrównania kolorytu skóry czy zmniejszenia rozszerzonych porów.
          Ze względu na swoją specyfikę mezoterapia nanoigłowa jest bardzo dobra w redukcji istniejących zmian
          skórnych i w profilaktyce, ale jednocześnie nie narusza ona tak głęboko struktury skóry jak mezoterapia
          igłowa czy też mikroigłowa.To powoduje, że jest polecana dla skór cienkich, silnie unaczynionych,
          wrażliwych.`,
    },
    {
      name: "Żelazko przeciwzmarszczkowe",
      // image: placeholderImageFour,
      subtitle: null,
      desc: `Dzięki jednoczesnemu oddziaływaniu dwóch zaawansowanych metod : lipodermologii (masaż vacum)
          oraz stymulacji TENS , Żelazko Przeciwzmarszczkowe pobudza naturalne mechanizmy fizjologiczne,
          skutecznie przeciwdziała efektom starzenia oraz pozwala szybko uzyskać optymalne rezultaty.
          Lipodermologia, czyli masaż próżniowy/vacum efektywnie stymuluje pracę układu limfatycznego i
          cyrkulację krwi wpływając na poprawę metabolizmu w miejscu poddanym zabiegowi.
          
          Efekty: działa ujędrniająco, eliminuje toksyny, poprawia ogólną kondycję skóry.

          Elektrostymulacja TENS to sprawdzona metoda łagodzenia bólu oraz leczenia zaburzeń funkcji mięśni i
          nerwów stosowana od lat w profesjonalnej fizykoterapii. Dzięki swojej efektywności elektrostymulacja
          TENS wykorzystana w Żelazku Przeciwzmarszczkowym doskonale sprawdza się także w kosmetologii.
          
          Dzięki szybkiej mikropulsacji stymuluje zakończenia nerwowe i pobudza mięśnie do pracy, a tym
          samym je regeneruje; zmniejsza uczucie napięcia mięśni i skóry i poprawia jej elastyczność; skutecznie 
          dotlenia i przyspiesza krążenie krwi; seria zabiegów zapewnia efekt liftingujący i modelujący twarz`,
    },
    {
      name: "Nanokrioterapia 5-10C LED BLUE",
      // image: placeholderImageSix,
      subtitle: "VOLUMETIQ LIFT",
      desc: `Niska temperatura wykorzystana podczas zabiegu wspomaga odnowę biologiczną skóry.
          Po zabiegu skóra staje się gładka i matowa, co ma kolosalne znaczenie zwłaszcza w przypadku skór
          problematycznych u nastolatków i dorosłych. Zimno doskonale radzi sobie z obrzękami powstałymi
          podczas snu.`,
    },
    {
      name: "Nanomezoterapia bezigłowa z fototerapią LED BLUE&GREEN",
      // image: placeholderImageSeven,
      subtitle: "VOLUMETIQ LIFT",
      desc: `Zabiegi NONOMEZOTERAPII BEZIGŁOWEJ opierają się na zastosowaniu mikroprądów do
          odżywiania głęboko położonych komórek, drenażu limfatycznego, przyspieszenia metabolizmu oraz
          poprawy przepływu limfy i krążenia krwi, a także wygładzania zmarszczek, rozjaśniania skóry i
          utrzymywania odpowiedniego jej nawilżenia.
          W zabiegu dodatkowo wykorzystuje się FOTOTERAPIĘ LED BLUE & GREEN opartą o światło
          niebieskie i zielone, które wykazuje działanie antybakteryjne i przeciwzapalne – hamuje rozwój stanów
          zapalnych u pacjentów z trądzikiem pospolitym i trądzikiem różowatym.`,
    },
    {
      name: "Nanokrystaliczna radiofrekwencja bipolarna",
      // image: placeholderImageOne,
      subtitle: "VOLUMETIQ LIFT",
      desc: `Ta technika działa jednocześnie na naskórek oraz głębokie warstwy skóry, oferując istotną poprawę w
          zakresie jędrności i napięcia.`,
    },
    {
      name: "Frakcyjna mezoterapia nanocrystal pen",
      // image: placeholderImageTwo,
      subtitle: "VOLUMETIQ LIFT",
      desc: `Nowa technologia drgania ( mezoterapia nanoigłowa) o wysokiej częstotliwości gwarantuje skuteczność
          we wtłaczaniu substancji aktywnych i różnych koktajli zabiegowych.`,
    },
    {
      name: "Mikropeeling nanocrystal pen",
      // image: placeholderImageThree,
      subtitle: "VOLUMETIQ LIFT",
      desc: `Innowacyjne rozwiązanie z kierunku mikrozłuszczania warstwy rogowej naskórka za pomocą
          nanodyskowej technologii i odpowiedniego ruchu głowicy do mikrozłuszczania i poprawy mikrokrążenia
          skóry.
          Technologia gwarantuje skuteczne oczyszczenie skóry i przygotowanie do dalszych etapów wielu
          zabiegów w profesjonalnym gabinecie. To idealna alternatywa dla zabiegów mikrodermabrazji lub
          złuszczania mechanicznego.`,
    },
    {
      name: "Kinesiotaping BIO estetyczny",
      // image: placeholderImageSeven,
      subtitle: null,
      desc: `Kinesiotaping BIO-estetyczny wspiera lifting twarzy, pozwala niwelować napięcia mięśniowe, zastoje
          limfatyczne w obszarze twarzy, szyi i dekoltu, przywraca właściwy tor oddechowy.
          To niezwykle istotny i wysoce skuteczny element odnowy biologicznej, efektywna forma rehabilitacji
          estetycznej tkanek naszej twarzy, szyi,dekoltu oraz biustu. Wspaniała metoda, bez której rehabilitacja czy
          terapia odnowy biologicznej twarzy staje się niepełna.
          Kinesiotaping to skuteczna aplikacja taśmowa wspierająca i wzmacniająca efekty zabiegów i terapii
          gabietowych.
          Plastrowanie wspaniale uwalnia napięcia oraz modeluje mięśnie w obszarze dekoltu, szyi i twarzy.
          Aplikacje wspomagają drenaż limfatyczny, regenerują tkanki np. po zabiegach chirurgicznych bądź
          zabiegach medycyny estetycznej.`,
    },
    {
      name: "Kwasy",
      // image: placeholderImageOne,
      subtitle: null,
      desc: `Usuwają zrogowaciały naskórek, dzięki czemu regulują odnowę komórkową skóry.
          Mają działanie antybakteryjne, dzięki czemu zapobiegają i wspierają leczenie wyprysków.
          Peelingi kwasowe przygotowują skórę na wszelkie zabiegi kosmetyczne – usunięcie martwego naskórka
          sprawia, iż skóra absorbuje o wiele więcej składników odżywczych.
          
          Stosowane w odpowiednich stężeniach mogą przynieść szereg korzyści dla skóry. Są wykorzystywane do
          walki z trądzikiem, zmarszczkami, plamami starczymi, bliznami i nierównym kolorytem skóry.`,
    },
    {
      name: "Peeling kawitacyjny",
      // image: placeholderImageTwo,
      subtitle: null,
      desc: `Kawitacja to metoda bezbolesnego złuszczania skóry, która stanowi alternatywę dla manualnego
          oczyszczania twarzy. Zabieg ten wykonuje się za pomocą specjalnego urządzenia emitującego fale
          ultradźwiękowe, które przy kontakcie z wilgotną skórą wywołują efekt kawitacji.
          Substancja płynna na skórze ( tonik) tworzy mikroskopijne pęcherzyki wypełnione rozrzedzonym gazem,
          które wskutek działania fal ultradźwiękowych gwałtownie pękają, prowadząc do rozbicia martwych
          komórek naskórka.
          Peeling kawitacyjny przynosi natychmiastową i widoczną poprawę kondycji skóry.`,
    },
    {
      name: "Oczyszczanie wodorodowe",
      // image: placeholderImageThree,
      subtitle: null,
      desc: `Oczyszczanie wodorowe należy do grupy zabiegów, które można wykonywać przez cały rok. Preparaty
          nie powodują łuszczenia skóry, zatem terapia z powodzeniem może być stosowana w okresie letnim
          To rytuał pielęgnacyjny bazujący na 6 postępujących po sobie etapach, mających na celu dogłębne
          oczyszczenie, złuszczenie, dotlenienie oraz lifting. Synergiczne połączenie metod hydrabrazji, infuzji,
          ultradźwięków oraz bipolarnego RF stanowi optymalną kombinację, umożliwiającą uzyskanie
          zadowalających efektów już po pierwszym zabiegu!`,
    },
    {
      name: "Masaż tlenowy",
      // image: placeholderImageThree,
      subtitle: null,
      desc: `Za pomocą specjalnej głowicy Oxy Massage, wykonuje się autorski masaż dermotlenowy, który w
          bezbolesny, bezpieczny i ciekawy sposób dostarcza skórze niezwykle cennych substancji zawartych w
          
          firmowych koncentratach oraz zaopatruje komórki w życiodajny tlen.
          Oxy Massage jest bardzo przyjemny i z powodzeniem można go łączyć z innymi technikami masażu.`,
    },
    {
      name: "Masaż próżniowy",
      // image: placeholderImageOne,
      subtitle: null,
      desc: `Lipodermologia, czyli masaż próżniowy/vacum efektywnie stymuluje pracę układu limfatycznego i
          cyrkulację krwi wpływając na poprawę metabolizmu w miejscu poddanym zabiegowi.
          
          Efekty: działa ujędrniająco, eliminuje toksyny, poprawia ogólną kondycję skóry.

          Zabieg ten zaleca się w obecności prądów TENS (żelazko przeciwzmarszczkowe)`,
    },
  ];

  const [screenSize, setScreenSize] = useState("laptop");
  useEffect(() => {
    if (size.width > 1350) {
      setScreenSize("laptop");
    } else if (size.width > 1000) {
      setScreenSize("tablet");
    } else if (size.width > 700) {
      setScreenSize("phone");
    } else {
      setScreenSize("small");
    }
  }, [size.width]);

  return (
    <div>
      <div className={offerFacialStyles.header}>
        <h1>Zabiegi na twarz</h1>
        {/* <a
          target="_blank"
          href="https://booksy.com/pl-pl/13116_lumio-skin-gabinet-kosmetyczny-magdalena-lebek_salon-kosmetyczny_3_warszawa"
        >
          Zarezerwuj wizytę
        </a> */}
      </div>
      <div className={offerFacialStyles.firstSection}>
        <span>Wszystko czego potrzebujesz w jednym miejscu.</span>
        <div>
          {additions.map((addition, adId) => (
            <>
              <div className={offerFacialStyles.descSection}>
                <div className={offerFacialStyles.greyCircle} />
                <span>{addition}</span>
              </div>
              {additions.length - 1 > adId && (
                <div className={offerFacialStyles.horizontalLine}></div>
              )}
            </>
          ))}
        </div>
      </div>
      <div className={offerFacialStyles.secondSection}>
        {screenSize !== "phone" && screenSize !== "small" && (
          <div>
            <h5>Nazwa zabiegu</h5>
            <div className="list-group">
              {facials.map((facial, id) => (
                <a key={id} href={`#${id}`} style={{ textDecoration: "none" }}>
                  <Link
                    to={`/offer-face#${id}`}
                    smooth
                    style={{ textDecoration: "none" }}
                    className={offerFacialStyles.facial}
                    data-to-scrollspy-id={id}
                    onClick={() => setSelectedFacial(id)}
                    id={`nav-${id}`}
                  >
                    {selectedFacial === id && (
                      <div className={offerFacialStyles.arrowCircle}>
                        <span className="material-icons">east</span>
                      </div>
                    )}
                    <span
                      className={offerFacialStyles.facialText}
                      style={{
                        color: selectedFacial === id ? "black" : "grey",
                      }}
                    >
                      {facial.name}
                    </span>
                  </Link>
                  {id !== facials.length - 1 && (
                    <div className={offerFacialStyles.divider} />
                  )}
                </a>
              ))}
            </div>
          </div>
        )}
        {/* <div className={offerFacialStyles.rightSectionFacials}> */}
        <div className={offerFacialStyles.rightSectionFacialsTemp}>
          <ScrollSpy
            offsetTop={80}
            onUpdateCallback={(id) => setSelectedFacial(Number(id))}
          >
            {facials.map((facial, facialId) => (
              <div id={facialId}>
                {/* {(facialId % 2 === 0 || screenSize === "small") && (
                  <img src={facial.image} />
                )} */}
                <div
                  className={offerFacialStyles.textBox}
                  // style={
                  //   screenSize !== "small"
                  //     ? {
                  //         transform: `translate(${
                  //           facialId % 2 === 0 ? "-" : ""
                  //         }${
                  //           screenSize === "laptop" ? "100px" : "20px"
                  //         }, 50px)`,
                  //       }
                  //     : {}
                  // }
                >
                  <span>{facial.name}</span>
                  {facial.subtitle !== null && <h4>{facial.subtitle}</h4>}
                  <div>
                    <p>{facial.desc}</p>
                    <a
                      target="_blank"
                      href="https://booksy.com/pl-pl/13116_lumio-skin-gabinet-kosmetyczny-magdalena-lebek_salon-kosmetyczny_3_warszawa"
                    >
                      Booksy
                    </a>
                  </div>
                </div>
                {/* {screenSize !== "small" && facialId % 2 !== 0 && (
                  <img src={facial.image} />
                )} */}
              </div>
            ))}
          </ScrollSpy>
        </div>
      </div>
    </div>
  );
};

export default OfferFacial;
