import React, { useState } from "react";
import contactUsStyles from "../styles/contactUsStyles.module.css";
import emailjs from "@emailjs/browser";

const ContactUs = () => {
  emailjs.init("Pz8QX9ofxvH5gA0gI");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [checkbox, setCheckbox] = useState(false);
  const [resonForContacting, setReasonForContacting] = useState("");

  const isValidEmail = (email) => {
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  };

  function sendEmail(e) {
    console.log(checkbox);
    e.preventDefault();
    if (
      firstName.length > 0 &&
      lastName.length > 0 &&
      isValidEmail(email) &&
      message.length > 0 &&
      phone.length === 9 &&
      resonForContacting.length > 0 &&
      checkbox
    ) {
      const serviceId = "service_hbxu5cp";
      const templateId = "template_0f8qz0s";
      const templateParams = {
        firstName,
        lastName,
        phone,
        resonForContacting,
        email,
        message,
      };

      emailjs
        .send(serviceId, templateId, templateParams)
        .then((response) => {
          console.log(response);
          setFirstName("");
          setLastName("");
          setReasonForContacting("");
          setPhone("");
          setEmail("");
          setMessage("");
          alert("Thank you for your message, we will be in touch in no time!");
        })
        .then((error) => console.log(error));
    } else {
      alert("Please fill in all of the required fields.");
    }
  }
  return (
    <div>
      <div className={contactUsStyles.topSection}>
        <div>
          <h5>skontaktuj się z nami</h5>
          <h1>Chętnie odpowiemy na wszystkie wasze pytania</h1>
        </div>
        <p>
          Zachęcam do skontaktowania się ze mną w razie jakichkolwiek
          niejasności lub wątpliwości. Postaram się odpowiedzieć na wszystkie
          pytania w jak najkrószym czasie.
        </p>
      </div>
      <form className={contactUsStyles.form} onSubmit={sendEmail}>
        <div className={contactUsStyles.fields}>
          <div>
            <span>first name</span>
            <input
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div>
            <span>last name</span>
            <input
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div>
            <span>email</span>
            <input value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
          <div>
            <span>phone number</span>
            <input value={phone} onChange={(e) => setPhone(e.target.value)} />
          </div>
          <div>
            <span>reason for contacting</span>
            <input
              value={resonForContacting}
              onChange={(e) => setReasonForContacting(e.target.value)}
            />
          </div>
        </div>
        <div className={contactUsStyles.textArea}>
          <span>your message</span>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div className={contactUsStyles.terms}>
          <div style={{ padding: "0 20px" }}>
            <span>TERMS AND CONDITIONS</span>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <input
                style={{ marginRight: "15px" }}
                type="checkbox"
                value={checkbox}
                onChange={(e) => setCheckbox(e.target.checked)}
              />
              <p style={{ textAlign: "start" }}>
                Poprzez podanie swojego numeru telefonu, udzielam firmie Lumio
                Skin wyraźnej, pisemnej zgody na kontaktowanie się ze mną
                poprzez wysyłanie wiadomości tekstowych na podany numer i
                e-mail. Z informacją o przetwarzaniu danych osobowych można
                zapoznać się{" "}
                <a
                  style={{ color: "black" }}
                  href={require("../images/RODO-Lumioskin.pdf")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  tutaj
                </a>
                .
              </p>
            </div>
          </div>
          <input type="submit" value="SUBMIT" style={{ color: "black" }} />
        </div>
      </form>
    </div>
  );
};

export default ContactUs;
