import React, { useState, useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import offerFacialStyles from "../styles/offerFacialStyles.module.css";
import { useWindowSize } from "../components/Navbar";
import ScrollSpy from "react-ui-scrollspy";

const OfferFacial = () => {
  const size = useWindowSize();
  const [selectedFacial, setSelectedFacial] = useState(0);
  const placeholderImageOne = require("../images/cm.png");
  const additions = [
    "Bezbolesne zabiegi",
    "Skuteczne terapie",
    "Redukcja cm w obwodzie",
  ];
  const facials = [
    {
      name: "l-Lipo Redukcja tkanki tłuszczowej",
      image: placeholderImageOne,
      desc: `Efekty odchudzania widać już po pierwszym zabiegu w postaci utraty centymetrów w obszarze zabiegowym. Mogą to 
      być uda, ramiona, pośladki ta brzuch. Zabieg jest całkowicie nieinwazyjny i bezbolesny. W miejscu zabiegowym nie pozostawia
      żadnych niepożądanych śladów. Lipolaser działa jedynie na komórki tłuszczowe. Nie uszkadza sąsiednich tkanek i naczyń
      krwionośnych. Nie stwarza zagrożenia powstania nierówności, zapobiega wiotczeniu skóry oraz doprowadza
      do redukcji cellulitu. Nie niszczy błony komórek tłuszczowych, a jedynie redukuje w sposób znaczny i kontrolowany
      ich zawartość i objętość.`,
    },
  ];

  const [screenSize, setScreenSize] = useState("laptop");
  useEffect(() => {
    if (size.width > 1350) {
      setScreenSize("laptop");
    } else if (size.width > 1000) {
      setScreenSize("tablet");
    } else if (size.width > 700) {
      setScreenSize("phone");
    } else {
      setScreenSize("small");
    }
  }, [size.width]);

  return (
    <div>
      <div
        className={offerFacialStyles.header}
        style={{ backgroundColor: "#7E7975" }}
      >
        <h1>Zabiegi na ciało</h1>
        {/* <a
          target="_blank"
          href="https://booksy.com/pl-pl/13116_lumio-skin-gabinet-kosmetyczny-magdalena-lebek_salon-kosmetyczny_3_warszawa"
        >
          Zarezerwuj wizytę
        </a> */}
      </div>
      <div className={offerFacialStyles.firstSection}>
        <span>Wszystko czego potrzebujesz w jednym miejscu</span>
        <div>
          {additions.map((addition, adId) => (
            <>
              <div className={offerFacialStyles.descSection}>
                <div className={offerFacialStyles.greyCircle} />
                <span>{addition}</span>
              </div>
              {additions.length - 1 > adId && (
                <div className={offerFacialStyles.horizontalLine}></div>
              )}
            </>
          ))}
        </div>
      </div>
      <div className={offerFacialStyles.secondSection}>
        {screenSize !== "phone" && screenSize !== "small" && (
          <div>
            <h5>Nazwa zabiegu</h5>
            <div>
              {facials.map((facial, id) => (
                <div key={id}>
                  <Link
                    to={`/offer-body#${id}`}
                    smooth
                    style={{ textDecoration: "none" }}
                    className={offerFacialStyles.facial}
                    data-to-scrollspy-id={id}
                    onClick={() => setSelectedFacial(id)}
                    id={`nav-${id}`}
                  >
                    {selectedFacial === id && (
                      <div
                        className={offerFacialStyles.arrowCircle}
                        style={{ backgroundColor: "#7E797540" }}
                      >
                        <span className="material-icons">east</span>
                      </div>
                    )}
                    <span
                      className={offerFacialStyles.facialText}
                      style={{
                        color: selectedFacial === id ? "black" : "grey",
                      }}
                    >
                      {facial.name}
                    </span>
                  </Link>
                  {id !== facials.length - 1 && (
                    <div className={offerFacialStyles.divider} />
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        <div className={offerFacialStyles.rightSectionFacials}>
          <ScrollSpy
            offsetTop={80}
            onUpdateCallback={(id) => setSelectedFacial(Number(id))}
          >
            {facials.map((facial, facialId) => (
              <div id={facialId}>
                {(facialId % 2 === 0 || screenSize === "small") && (
                  <img src={facial.image} />
                )}
                <div
                  className={offerFacialStyles.textBox}
                  style={
                    screenSize !== "small"
                      ? {
                          transform: `translate(${
                            facialId % 2 === 0 ? "-" : ""
                          }${
                            screenSize === "laptop" ? "100px" : "20px"
                          }, 50px)`,
                        }
                      : {}
                  }
                >
                  <span>{facial.name}</span>
                  <div>
                    <p>{facial.desc}</p>
                    <a
                      target="_blank"
                      href="https://booksy.com/pl-pl/13116_lumio-skin-gabinet-kosmetyczny-magdalena-lebek_salon-kosmetyczny_3_warszawa"
                    >
                      Booksy
                    </a>
                  </div>
                </div>
                {screenSize !== "small" && facialId % 2 !== 0 && (
                  <img src={facial.image} />
                )}
              </div>
            ))}
          </ScrollSpy>
        </div>
      </div>
    </div>
  );
};

export default OfferFacial;
