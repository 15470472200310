import React from "react";
import aboutUsStyles from "../styles/aboutUsStyles.module.css";
import { useWindowSize } from "../components/Navbar";

const AboutUs = () => {
  const size = useWindowSize();
  const magda = require("../images/1710748921872.jpeg");
  const certOne = require("../images/cert1.png");
  const certTwo = require("../images/cert2.png");
  const certThree = require("../images/cert3.png");

  return (
    <div className={aboutUsStyles.body}>
      <div className={aboutUsStyles.background}></div>
      <h5>O NAS</h5>
      <div className={aboutUsStyles.secondSection}>
        <div>
          <h2>
            Pozwól, że zabiorę Cię do mojego świata ... świata kosmetologii,
            którego do tej pory nie doświadczyłaś...
          </h2>
          <p>
            Moją ideą jest dbanie nie tylko o wygląd, ale również o zdrowe ciało
            i umysł. „Z myślą o Tobie” tym właśnie kieruję się w pracy
            kosmetologa. Dotyczy to zarówno tego co dzieje się w gabinecie
            podczas naszych zabiegów, jak i również w zaciszu domowym. Tworząc
            spersonalizowaną kurację domową masz pewność, że dbasz o swoją skórę
            prawidłowo.
          </p>
        </div>
      </div>
      <div className={aboutUsStyles.firstSection}>
        <img src={magda} className={aboutUsStyles.ownerImage} />
        <div className={aboutUsStyles.description}>
          <h4>
            W gabinecie łączę wiedzę, pasję, doświadczenie i chęć pomocy.
            Przygoda ta zaczęła się już w 2013 roku i jestem ogromnie
            szczęśliwa, że mogę pracować w zawodzie, który zdecydowanie jest
            moją pasją.
          </h4>
          <p>
            W Lumio Skin oprócz wielofazowych zabiegów pielęgnacyjnych o
            wysokiej jakości i skuteczności są również zabiegi wykonywane przy
            pomocy nowoczesnych urządzeń.
          </p>
          <p>
            Terapia przeciwtrądzikowa oraz działanie anti-aging to jedne z
            wiodących motywów. Z wykształcenia jestem również masażystą, więc w
            moim gabinecie doświadczysz również strefy relaksu i odprężenia.
            Poznasz magię masażu czy też refleksoterapii stóp.
          </p>
          <p>
            Pozwól, że wprowadzę Cię do mojego świata począwszy od konsultacji
            gdzie ustalimy plan działania po piękną i zdrową skórę,
          </p>
        </div>
      </div>
      <div className={aboutUsStyles.thirdSection}>
        <h1>Usługi świadczone na najwyższym poziomie</h1>
        <p>
          Ukończyłam studia na kierunku kosmetologii w specjalności kosmetologii
          dermatologicznej w Bydgoszczy. Systematycznie poszerzam swoją wiedzę
          na licznych szkoleniach w zakresie zdrowia i pielęgnacji skóry.
        </p>
      </div>
      <div className={aboutUsStyles.fourthSection}>
        <img alt="LumioSkin certification dermaquest" src={certOne} />
        <img alt="LumioSkin certification nanili" src={certTwo} />
        <img alt="LumioSkin certification yonelle" src={certThree} />
      </div>
    </div>
  );
};

export default AboutUs;
