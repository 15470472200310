import React, { useState, useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import offerFacialStyles from "../styles/offerFacialStyles.module.css";
import { useWindowSize } from "../components/Navbar";
import ScrollSpy from "react-ui-scrollspy";

const OfferOther = () => {
  const size = useWindowSize();
  const [selectedMassage, setSelectedMassage] = useState(0);
  const placeholderImageOne = require("../images/1.jpg");
  const placeholderImageTwo = require("../images/1708431557635.jpg");
  const additions = [
    "Wosk bezpaskowy",
    "Długotrwałe efekty",
    "Sprawnie i skutecznie",
  ];
  const procedures = [
    {
      name: "Henna",
      image: placeholderImageOne,
      subtitle: null,
      desc: `Barwnik zawarty w hennie wnika w głąb włosów, wiążąc się z keratyną. Dzięki temu uzyskany kolor 
            jest trwały i zupełnie naturalny. Włosy zafarbowane henną nie niszczą się jak przy użyciu tradycyjnych 
            farb. Co więcej, stają się one grubsze, nabierają blasku i zdrowego wyglądu. 
            
            Do wyboru jest henna brwi i rzęs. Zabieg może być wykonywany również w połączeniu z regulacją włosków.`,
    },
    {
      name: "Depilacja woskiem",
      image: placeholderImageTwo,
      subtitle: null,
      desc: `Depilacja ta polega na nakładaniu na skórę półpłynnego wosku, ogrzanego do odpowiedniej temperatury. Włos 
            jest bowiem wyrywany razem z cebulką, co uniemożliwia jego odrastanie. Regularne stosowanie depilacji woskiem 
            osłabia owłosienie, a każda kolejna depilacja jest mniej bolesna. Do wyboru jest depilacja brody i górnej wargi.
      `,
    },
  ];

  const [screenSize, setScreenSize] = useState("laptop");
  useEffect(() => {
    if (size.width > 1350) {
      setScreenSize("laptop");
    } else if (size.width > 1000) {
      setScreenSize("tablet");
    } else if (size.width > 700) {
      setScreenSize("phone");
    } else {
      setScreenSize("small");
    }
  }, [size.width]);

  return (
    <div>
      <div
        className={offerFacialStyles.header}
        style={{ backgroundColor: "#7E7975" }}
      >
        <h1>Inne oferowane usługi</h1>
        {/* <a
          target="_blank"
          href="https://booksy.com/pl-pl/13116_lumio-skin-gabinet-kosmetyczny-magdalena-lebek_salon-kosmetyczny_3_warszawa"
        >
          Zarezerwuj wizytę
        </a> */}
      </div>
      <div className={offerFacialStyles.firstSection}>
        <span>Wszystko czego potrzebujesz w jednym miejscu</span>
        <div>
          {additions.map((addition, adId) => (
            <>
              <div className={offerFacialStyles.descSection}>
                <div className={offerFacialStyles.greyCircle} />
                <span>{addition}</span>
              </div>
              {additions.length - 1 > adId && (
                <div className={offerFacialStyles.horizontalLine}></div>
              )}
            </>
          ))}
        </div>
      </div>
      <div className={offerFacialStyles.secondSection}>
        {screenSize !== "phone" && screenSize !== "small" && (
          <div>
            <h5>Nazwa zabiegu</h5>
            <div>
              {procedures.map((facial, id) => (
                <div key={id}>
                  <Link
                    to={`/offer-massage#${id}`}
                    smooth
                    style={{ textDecoration: "none" }}
                    className={offerFacialStyles.facial}
                    data-to-scrollspy-id={id}
                    onClick={() => setSelectedMassage(id)}
                    id={`nav-${id}`}
                  >
                    {selectedMassage === id && (
                      <div
                        className={offerFacialStyles.arrowCircle}
                        style={{ backgroundColor: "#7E797540" }}
                      >
                        <span className="material-icons">east</span>
                      </div>
                    )}
                    <span
                      className={offerFacialStyles.facialText}
                      style={{
                        color: selectedMassage === id ? "black" : "grey",
                      }}
                    >
                      {facial.name}
                    </span>
                  </Link>
                  {id !== procedures.length - 1 && (
                    <div className={offerFacialStyles.divider} />
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        {/* <div className={offerFacialStyles.rightSectionFacials}> */}
        <div className={offerFacialStyles.rightSectionFacialsTemp}>
          <ScrollSpy
            offsetTop={80}
            onUpdateCallback={(id) => setSelectedMassage(Number(id))}
          >
            {procedures.map((facial, facialId) => (
              <div id={facialId}>
                {/* {(facialId % 2 === 0 || screenSize === "small") && (
                  <img src={facial.image} />
                )} */}
                <div
                  className={offerFacialStyles.textBox}
                  // style={
                  //   screenSize !== "small"
                  //     ? {
                  //         transform: `translate(${
                  //           facialId % 2 === 0 ? "-" : ""
                  //         }${
                  //           screenSize === "laptop" ? "100px" : "20px"
                  //         }, 50px)`,
                  //       }
                  //     : {}
                  // }
                >
                  <span>{facial.name}</span>
                  <div>
                    <p>{facial.desc}</p>
                    <a
                      target="_blank"
                      href="https://booksy.com/pl-pl/13116_lumio-skin-gabinet-kosmetyczny-magdalena-lebek_salon-kosmetyczny_3_warszawa"
                    >
                      Booksy
                    </a>
                  </div>
                </div>
                {/* {screenSize !== "small" && facialId % 2 !== 0 && (
                  <img src={facial.image} />
                )} */}
              </div>
            ))}
          </ScrollSpy>
        </div>
      </div>
    </div>
  );
};

export default OfferOther;
