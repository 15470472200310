import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import navbarStyles from "../styles/navbarStyles.module.css";
import Hamburger from "hamburger-react";

const Navbar = () => {
  const size = useWindowSize();
  const [screenSize, setScreenSize] = useState("laptop");
  useEffect(() => {
    if (size.width > 950) {
      setScreenSize("laptop");
    } else {
      setScreenSize("tablet");
    }
  }, [size.width]);
  return screenSize === "laptop" ? <HorizontalNav /> : <VerticalNav />;
};

const VerticalNav = () => {
  const location = useLocation();
  const [navBarOpened, setNavbarOpened] = useState(false);
  const logo = require("../images/logo.png");

  React.useEffect(() => {
    setNavbarOpened(false);
  }, [location]);
  return (
    <div>
      <div className={navbarStyles.vertNavbar}>
        <Link to="/">
          <img alt="LumioSkin Logo" src={logo} />
        </Link>
        <Hamburger toggled={navBarOpened} size={32} toggle={setNavbarOpened} />
      </div>
      {navBarOpened && (
        <>
          <div className={navbarStyles.vertNavbarBackground}></div>
          <div className={navbarStyles.vertNavbarMenu}>
            <div>
              <div>
                <div>
                  <Link to="/">Główna</Link>
                </div>
                <div>
                  <Link to="/offer-face">Zabiegi na twarz</Link>
                </div>
                <div>
                  <Link to="/offer-body">Zabiegi na ciało</Link>
                </div>
                <div>
                  <Link to="/offer-massage">Masaż</Link>
                </div>
                <div>
                  <Link to="/offer-other">Inne zabiegi</Link>
                </div>
                <div>
                  <Link to="/offer-voucher">Voucher</Link>
                </div>
              </div>
              <div>
                <div>
                  <Link to="/about-us">O nas</Link>
                </div>
                <div>
                  <Link to="/location">Lokalizacja</Link>
                </div>
                <div>
                  <Link to="/contact">Kontakt</Link>
                </div>
              </div>
            </div>
            <a
              target="_blank"
              href="https://booksy.com/pl-pl/13116_lumio-skin-gabinet-kosmetyczny-magdalena-lebek_salon-kosmetyczny_3_warszawa"
            >
              Zarezerwuj wizytę
            </a>
          </div>
        </>
      )}
      <div style={{ height: "85px" }}></div>
    </div>
  );
};

const HorizontalNav = () => {
  const [offerMenuOpened, setOfferMenuOpened] = useState(false);
  const logo = require("../images/logo.png");
  return (
    <div className={navbarStyles.navbar}>
      <Link to="/">
        <img src={logo} />
      </Link>
      <div className={navbarStyles.links}>
        <div
          className={offerMenuOpened ? navbarStyles.opened : {}}
          onClick={() => setOfferMenuOpened(true)}
          onMouseEnter={() => setOfferMenuOpened(true)}
          onMouseLeave={() => setOfferMenuOpened(false)}
        >
          <a>Usługi</a>
          <div>
            <span className="material-icons">expand_more</span>
          </div>
        </div>
        {offerMenuOpened && (
          <div
            onMouseEnter={() => setOfferMenuOpened(true)}
            onMouseLeave={() => setOfferMenuOpened(false)}
            className={navbarStyles.dropdown}
          >
            <Link to="/offer-face">Zabiegi na twarz</Link>
            <Link to="/offer-body">Zabiegi na ciało</Link>
            <Link to="/offer-massage">Masaż</Link>
            <Link to="/offer-other">Inne zabiegi</Link>
            <Link to="/offer-voucher">Voucher</Link>
          </div>
        )}
        <div>
          <Link to="/about-us">O nas</Link>
        </div>
        <div>
          <Link to="/location">Lokalizacja</Link>
        </div>
        <div>
          <Link to="/contact">Kontakt</Link>
        </div>
        <a
          target="_blank"
          href="https://booksy.com/pl-pl/13116_lumio-skin-gabinet-kosmetyczny-magdalena-lebek_salon-kosmetyczny_3_warszawa"
        >
          Zarezerwuj wizytę
        </a>
      </div>
    </div>
  );
};

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export { Navbar, useWindowSize };
