import React, { useState, useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import offerFacialStyles from "../styles/offerFacialStyles.module.css";
import { useWindowSize } from "../components/Navbar";
import ScrollSpy from "react-ui-scrollspy";

const OfferMassage = () => {
  const size = useWindowSize();
  const [selectedMassage, setSelectedMassage] = useState(0);
  const placeholderImageOne = require("../images/massage2.png");
  const placeholderImageTwo = require("../images/1708431557629.jpg");
  const placeholderImageThree = require("../images/massage3.png");
  const placeholderImageFour = require("../images/3.jpg");
  const additions = [
    "Aromaterapia",
    "Ukojenie zmysłów",
    "Łóżko z masażem",
  ];
  const procedures = [
    {
      name: "Masaż dłoni i stóp",
      image: placeholderImageFour,
      subtitle: null,
      desc: `Wywiera korzystny wpływ na cały organizm, powodując stan ogólnego
            odprężenia. Działa relaksująco i antystresowo. Likwiduje uczucie zmęczenia oraz napięcia
            mięśniowego. Zmniejsza dolegliwości bólowe, powoduje poprawę mikrokrążenia oraz
            odbudowę ruchomości i elastyczności drobnych stawów. Przynosi niezwykłe ukojenie,
            zwłaszcza osobom pracującym dłońmi lub spędzającym długie godziny w pozycji stojącej.
            Dodatkowo podczas masażu możemy skorzystać z łóżka z funkcją masażu i podgrzewania.`,
    },
    {
      name: "Masaż autorski",
      image: placeholderImageTwo,
      subtitle: null,
      desc: `To kompleksowa pielęgnacja o wyjątkowo relaksującym przebiegu i doskonałych efektach. Rytuały i
          masaże pozwalają na całkowitą odnowę organizmu, przywracając ciału równowagę i stan uspokojenia.
          Stres i czynniki zewnętrzne powodują utratę elastyczności. Tkanka skórna staje się mniej jędrna, a
          zmarszczki bardziej widoczne. Zastrzyk regenerujących składników sprawi, że skóra będzie wyglądać
          znów pięknie, młodo i świeżo.
          
          Masaż twarzy, szyi, dekoltu, ramion oraz głowy przeniesie Cię w świat relaksu. Jest to masaż, który
          pozwoli zmniejszyć napięcie w ciele oraz przyniesie ukojenie umysłu i duszy.
          Królewski Lift YONELLE, intensywny masaż na zmarszczki mimiczne Terapia Młodości czy Królewski
          Masaż Karku to wyjątkowe lecz nieostateczne połączenie technik.
          Podczas zabiegu doświadczysz wielu nieoczekiwanych doznań. Specjalne urządzenia do masażu
          spotęgują uczucie relaksu i odprężenia.`,
    },
    {
      name: "Masaż tlenowy",
      image: placeholderImageThree,
      subtitle: null,
      desc: `Za pomocą specjalnej głowicy Oxy Massage, wykonuje się autorski masaż dermotlenowy, który w
          bezbolesny, bezpieczny i ciekawy sposób dostarcza skórze niezwykle cennych substancji zawartych w
          
          firmowych koncentratach oraz zaopatruje komórki w życiodajny tlen.
          Oxy Massage jest bardzo przyjemny i z powodzeniem można go łączyć z innymi technikami masażu.`,
    },
    {
      name: "Masaż próżniowy",
      image: placeholderImageOne,
      subtitle: null,
      desc: `Lipodermologia, czyli masaż próżniowy/vacum efektywnie stymuluje pracę układu limfatycznego i
          cyrkulację krwi wpływając na poprawę metabolizmu w miejscu poddanym zabiegowi.
          
          Efekty: działa ujędrniająco, eliminuje toksyny, poprawia ogólną kondycję skóry.

          Zabieg ten zaleca się w obecności prądów TENS (żelazko przeciwzmarszczkowe).`,
    },
  ];

  const [screenSize, setScreenSize] = useState("laptop");
  useEffect(() => {
    if (size.width > 1350) {
      setScreenSize("laptop");
    } else if (size.width > 1000) {
      setScreenSize("tablet");
    } else if (size.width > 700) {
      setScreenSize("phone");
    } else {
      setScreenSize("small");
    }
  }, [size.width]);

  return (
    <div>
      <div
        className={offerFacialStyles.header}
        style={{ backgroundColor: "#7E7975" }}
      >
        <h1>Masaż</h1>
        {/* <a
          target="_blank"
          href="https://booksy.com/pl-pl/13116_lumio-skin-gabinet-kosmetyczny-magdalena-lebek_salon-kosmetyczny_3_warszawa"
        >
          Zarezerwuj wizytę
        </a> */}
      </div>
      <div className={offerFacialStyles.firstSection}>
        <span>Wszystko czego potrzebujesz w jednym miejscu</span>
        <div>
          {additions.map((addition, adId) => (
            <>
              <div className={offerFacialStyles.descSection}>
                <div className={offerFacialStyles.greyCircle} />
                <span>{addition}</span>
              </div>
              {additions.length - 1 > adId && (
                <div className={offerFacialStyles.horizontalLine}></div>
              )}
            </>
          ))}
        </div>
      </div>
      <div className={offerFacialStyles.secondSection}>
        {screenSize !== "phone" && screenSize !== "small" && (
          <div>
            <h5>Nazwa zabiegu</h5>
            <div>
              {procedures.map((facial, id) => (
                <div key={id}>
                  <Link
                    to={`/offer-massage#${id}`}
                    smooth
                    style={{ textDecoration: "none" }}
                    className={offerFacialStyles.facial}
                    data-to-scrollspy-id={id}
                    onClick={() => setSelectedMassage(id)}
                    id={`nav-${id}`}
                  >
                    {selectedMassage === id && (
                      <div
                        className={offerFacialStyles.arrowCircle}
                        style={{ backgroundColor: "#7E797540" }}
                      >
                        <span className="material-icons">east</span>
                      </div>
                    )}
                    <span
                      className={offerFacialStyles.facialText}
                      style={{
                        color: selectedMassage === id ? "black" : "grey",
                      }}
                    >
                      {facial.name}
                    </span>
                  </Link>
                  {id !== procedures.length - 1 && (
                    <div className={offerFacialStyles.divider} />
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        <div className={offerFacialStyles.rightSectionFacials}>
          <ScrollSpy
            offsetTop={80}
            onUpdateCallback={(id) => setSelectedMassage(Number(id))}
          >
            {procedures.map((facial, facialId) => (
              <div id={facialId}>
                {(facialId % 2 === 0 || screenSize === "small") && (
                  <img src={facial.image} />
                )}
                <div
                  className={offerFacialStyles.textBox}
                  style={
                    screenSize !== "small"
                      ? {
                          transform: `translate(${
                            facialId % 2 === 0 ? "-" : ""
                          }${
                            screenSize === "laptop" ? "100px" : "20px"
                          }, 50px)`,
                        }
                      : {}
                  }
                >
                  <span>{facial.name}</span>
                  <div>
                    <p>{facial.desc}</p>
                    <a
                      target="_blank"
                      href="https://booksy.com/pl-pl/13116_lumio-skin-gabinet-kosmetyczny-magdalena-lebek_salon-kosmetyczny_3_warszawa"
                    >
                      Booksy
                    </a>
                  </div>
                </div>
                {screenSize !== "small" && facialId % 2 !== 0 && (
                  <img src={facial.image} />
                )}
              </div>
            ))}
          </ScrollSpy>
        </div>
      </div>
    </div>
  );
};

export default OfferMassage;
