import React from "react";
import { Link } from "react-router-dom";
import footerStyles from "../styles/footerStyles.module.css";

const Footer = () => {
  const logo = require("../images/logo-white.png");
  return (
    <div className={footerStyles.body}>
      <div className={footerStyles.rightSection}>
        <div>
          <div className={footerStyles.navSection}>
            <span>Nasz gabinet</span>
            <div>
              <Link to="/offer-face">Zabiegi na twarz</Link>
              <Link to="/offer-body">Zabiegi na ciało</Link>
              <Link to="/offer-massage">Masaż</Link>
              <Link to="/offer-other">Pozostałe zabiegi</Link>
              <Link to="/offer-voucher">Voucher</Link>
              <Link to="/about-us">O nas</Link>
              <a
                target="_blank"
                href="https://booksy.com/pl-pl/13116_lumio-skin-gabinet-kosmetyczny-magdalena-lebek_salon-kosmetyczny_3_warszawa"
              >
                Booksy
              </a>
            </div>
          </div>
          <div className={footerStyles.navSection}>
            <span>Skontaktuj się z nami</span>
            <div>
              <span>kontakt@lumioskin.pl</span>
              <span>+48 537 100 265</span>
            </div>
          </div>
          <div className={footerStyles.navSection}>
            <span>Odwiedz nas</span>
            <div>
              <span>Aleja Solidarności 173</span>
              <span>00-877, Warszawa</span>
            </div>
          </div>
        </div>
        <iframe
          title="Google Maps LumioSkin"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2443.4193777525725!2d20.976086756693945!3d52.235765842743945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ecc827b9fd449%3A0xf4c1428c5c9e8891!2saleja%20%22Solidarno%C5%9Bci%22%20173%2C%2001-196%20Warszawa!5e0!3m2!1spl!2spl!4v1709492606593!5m2!1spl!2spl"
          width="300"
          height="280"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div className={footerStyles.leftSection}>
        <img alt="LumioSkin logo" src={logo} />
        <div className={footerStyles.logoSection}>
          <a
            style={{ color: "#7e7975" }}
            href="https://www.facebook.com/lumioskin"
            target="_blank"
          >
            <i className="fa-brands fa-facebook-f"></i>
          </a>
          <a
            style={{ color: "#7e7975" }}
            href="https://www.instagram.com/lumioskin/"
            target="_blank"
          >
            <i className="fab fa-instagram"></i>
          </a>
        </div>
        <span className={footerStyles.copyrigth}>
          Copyright © {new Date().getFullYear()} Lumio Skin. All Rights
          Reserved. <br />{" "}
          <a
            style={{ color: "white" }}
            href={require("../images/RODO-Lumioskin.pdf")}
            target="_blank"
            rel="noopener noreferrer"
          >
            RODO
          </a>
        </span>
      </div>
    </div>
  );
};

export default Footer;
