import React from "react";
import "./App.css";
import { Navbar } from "./components/Navbar";
import Footer from "./components/Footer";
import { Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import AboutUs from "./pages/AboutUs";
import OfferBody from "./pages/OfferBody";
import OfferFacial from "./pages/OfferFacial";
import OfferMassage from "./pages/OfferMassage";
import OfferOther from "./pages/OfferOther";
import ContactUs from "./pages/ContactUs";
import Location from "./pages/Location";
import OfferVoucher from "./pages/OfferVoucher";
import ScrollToTop from "./components/ScroolToTop";

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/offer-body" element={<OfferBody />} />
        <Route path="/offer-face" element={<OfferFacial />} />
        <Route path="/offer-massage" element={<OfferMassage />} />
        <Route path="/offer-other" element={<OfferOther />} />
        <Route path="/offer-voucher" element={<OfferVoucher />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/location" element={<Location />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
