import React from "react";
import { Link } from "react-router-dom";
import landingStyles from "../styles/landingStyles.module.css";

const Carousel = ({ selectedCard, screenSize, width }) => {
  const offerMassage = require("../images/1708431557629.jpg");
  const offerMezotherapy = require("../images/mezotherapy.png");
  const offerRevital = require("../images/1709291912436.jpg");
  const offer = [
    {
      image: offerMassage,
      title: "Masaż autorski",
      text: `Masaż autorski jest to połączenie kilku rodzajów masażu w celu 
            uzyskania jak najlepszego efektu terapeutycznego. Podczas wizyty 
            doznasz masażu: głowy, twarzy, szyji, dekoltu oraz karku. Przed 
            masażem zastosujemy peeling miodem, który zapewni lepszą 
            wchłanialność substancji. Techniki masażu momentami mogą być dosyć 
            intensywne, co przekłada się na zwiększoną trofikę tkanek! W trakcie 
            zabiegu używane są również specjalne głowice do masażu.`,
    },
    {
      image: offerMezotherapy,
      title: "Mezoterapia nanoigłowa",
      text: `Alternatywa mezoterapii mikroigłowej. Lifting, napięcie, rozświetlenie, 
            oczyszczenie, zwężenie porowatości. Głowica nie posiada igieł tylko wypustki. 
            Skutecznie pobudza układ krwionośny i limfatyczny dając efekt odświeżonej 
            skóry. Podczas zabiegu wprowadzamy składniki aktywne.`,
    },
    {
      image: offerRevital,
      title: "Kinesiotaping BIO-estetyczny",
      text: `Wspiera lifting twarzy, pozwala niwelować napięcia mięśniowe,
      zastoje limfatyczne w obszarze twarzy, szyi i dekoltu, przywraca właściwy tor oddechowy.
      To niezwykle istotny i wysoce skuteczny element odnowy biologicznej, efektywna forma
      rehabilitacji estetycznej tkanek naszej twarzy, szyi, dekoltu oraz biustu. Wspaniała metoda, bez
      której rehabilitacja czy terapia odnowy biologicznej twarzy staje się niepełna.`,
    },
  ];

  return (
    <div className={landingStyles.fourthSectionBottomWrapper}>
      <div
        className={landingStyles.fourthSectionBottom}
        style={{
          width: `${300 / (screenSize === 0 ? 1 : screenSize)}%`,
          transform: `translateX(-${
            ((width - (width > 600 ? 165 : 55)) * selectedCard) / (screenSize === 0 ? 1 : screenSize) - (screenSize === 0 ? -15*selectedCard : 0)
          }px)`,
        }}
      >
        {offer.map((facial, id) => (
          <div
            key={`facial=${id}`}
            style={{
              width: `calc(${100 / (screenSize === 0 ? 1 : screenSize)}% - ${
                20 * (screenSize !== 3 ? screenSize : 0)
              }px)`,
              margin: "0 10px",
            }}
          >
            <img src={facial.image} />
            <h3>{facial.title}</h3>
            <p>{facial.text}</p>
            <Link to="/offer-face">
              <span>Dowiedz się więcej</span>
              <span className="material-symbols-outlined">arrow_forward</span>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
