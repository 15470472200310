import React, { useState, useEffect } from "react";
import landingStyles from "../styles/landingStyles.module.css";
import Carousel from "../components/CarouselFacials";
import { useWindowSize } from "../components/Navbar";
import CarouselOpinions from "../components/CarouselOpinions";

const LandingPage = () => {
  const size = useWindowSize();
  const [selectedOpinion, setSelectedOpinion] = useState(0);
  const [screenSize, setScreenSize] = useState(3);
  const [selectedCard, setSelectedCard] = useState(0);
  const location = require("../images/location.png");
  const gift = require("../images/gift.jpg");
  const mainPage = require("../images/main.png");
  const secondSectionPicOne = require("../images/landing1.png");
  const secondSectionPicTwo = require("../images/1708431557611.jpg");
  const secondSectionPicThree = require("../images/1.jpg");
  const images = [
    secondSectionPicOne,
    secondSectionPicTwo,
    secondSectionPicThree,
  ];

  const procedureOne = require("../images/procedureOne.png");
  const procedureTwo = require("../images/zabieg2.png");
  const procedureThree = require("../images/hi- tech.jpg");

  useEffect(() => {
    setSelectedOpinion(0);
    setSelectedCard(0);
    if (size.width > 1100) {
      setScreenSize(3);
    } else if (size.width > 750) {
      setScreenSize(2);
    } else if (size.width > 600) {
      setScreenSize(1);
    } else {
      setScreenSize(0);
    }
  }, [size.width]);

  const procedures = [
    {
      image: procedureOne,
      title: "W gabinecie",
      text: "Każdy zabieg jest dobierany odpowiednio do stanu Twojej skóry, jej potrzeb i możliwości. Ustalimy plan spotkania i będziemy razem pracowały, aby zapewnić spełnienie Twoich oczekiwań.",
    },
    {
      image: procedureTwo,
      title: "W domu",
      text: "Dobierzemy specjalnie dla Ciebie zestaw kosmetyków do kuracji domowej. Dowiemy się na jakie składniki aktywne warto zwrócić uwagę, aby osiągnąć jeszcze lepsze efekty.",
    },
    {
      image: procedureThree,
      title: "Zabiegi hi-tech",
      text: "Bądź ze mną na bieżąco i dowiedz się o kolejnych nowościach, które przygotowuję właśnie dla Ciebie.",
    },
  ];
  const labelOne = require("../images/label1.png");
  const labelTwo = require("../images/label2.png");
  const labelThree = require("../images/label3.png");
  const labelFour = require("../images/label4.png");
  const labelFive = require("../images/label5.png");
  const labelSix = require("../images/tierra-removebg-preview.png");
  const labels = [
    labelOne,
    labelTwo,
    labelThree,
    labelFour,
    labelFive,
    labelSix,
  ];
  return (
    <div>
      <div className={landingStyles.topSectionWrapper}>
        <div className={landingStyles.gradient}></div>
        <div className={landingStyles.topSection}>
          <h1 style={{ marginBottom: "25px" }}>
            Zanurz się w relaksie <br />i odzyskaj blask <br /> swojej skóry.
          </h1>
          <a
            target="_blank"
            href="https://booksy.com/pl-pl/13116_lumio-skin-gabinet-kosmetyczny-magdalena-lebek_salon-kosmetyczny_3_warszawa"
          >
            Booksy
          </a>
        </div>
        <img src={mainPage} />
      </div>
      <div className={landingStyles.secondSection}>
        <h2>Jesteś w dobrych rękach</h2>
        <p>
          Wszechstronna opieka Twojej skóry w Lumio Skin. Otrzymasz
          spersonalizowany plan zabiegowy w gabinecie wraz z dobraną
          bezpośrednio do potrzeb Twojej skóry kuracją domową.
        </p>
        <div className={landingStyles.imageBox}>
          {images.map((img, id) => (
            <div style={id !== 0 ? { backgroundColor: "black" } : {}}>
              <img
                key={`img-${id}`}
                src={img}
                style={id !== 0 ? { opacity: id === 1 ? 0.82 : 0.86 } : {}}
              />
            </div>
          ))}
        </div>
      </div>
      <div className={landingStyles.thirdSection}>
        <h2>
          "Naszą skórą jesteśmy związani na zawsze, zadbajmy o nią najlepiej,
          jak potrafimy."
        </h2>
        <p>Gwyneth Paltrow</p>
      </div>
      <div className={landingStyles.fourthSection}>
        <div className={landingStyles.fourthSectionTop}>
          <div>
            <h2>Efektowne zabiegi w zasięgu ręki</h2>
            <div className={landingStyles.arrows}>
              <span
                onClick={() =>
                  selectedCard > 0 ? setSelectedCard(selectedCard - 1) : {}
                }
                style={{ opacity: selectedCard === 0 ? 0.5 : 1 }}
                className="material-symbols-outlined"
              >
                arrow_back
              </span>
              <span
                onClick={() =>
                  selectedCard < 3 - (screenSize === 0 ? 1 : screenSize)
                    ? setSelectedCard(selectedCard + 1)
                    : {}
                }
                style={{ opacity: selectedCard === 3 - (screenSize === 0 ? 1 : screenSize) ? 0.5 : 1 }}
                className="material-symbols-outlined"
              >
                arrow_forward
              </span>
            </div>
          </div>
          <div>
            <p>
              Wielofazowe zabiegi na nowoczesnych urządzeniach, w tym zabiegi na
              trądzik, rozświetlenie,lifting czy też nawilżenie skóry.
            </p>
            <a
              target="_blank"
              href="https://booksy.com/pl-pl/13116_lumio-skin-gabinet-kosmetyczny-magdalena-lebek_salon-kosmetyczny_3_warszawa"
            >
              Zobacz pełną ofertę
            </a>
          </div>
        </div>
        <Carousel
          selectedCard={selectedCard}
          screenSize={screenSize}
          width={size.width}
        />
      </div>
      <div className={landingStyles.ninthSection}>
        <p>Poznaj naszych partnerów</p>
        <div>
          {labels.map((label, id) => (
            <img key={`label-${id}`} src={label} />
          ))}
        </div>
      </div>
      <div className={landingStyles.fifthSection}>
        <h4>NASZA LOKALIZACJA</h4>
        <h1>Odwiedź nasz gabinet w Warszawie</h1>
        <img src={location} />
        <div>
          <h3>Wola</h3>
          <span>- pod adresem -</span>
          <p>Aleja Solidarności 173</p>
        </div>
      </div>
      <div className={landingStyles.sixthSection}>
        <img src={gift} />
        <div>
          <p>poznaj zalety bycia stałym klientem w naszym gabinecie</p>
          <h1>Zadbaj o swoją skórę i przygotuj się na niespodziankę</h1>
          <div>
            <div>Rabat 20% na pierwszą wizytę</div>
            <div>Rabat 30% za polecenie</div>
            <div>spersonalizowane zabiegi</div>
            <div>niespodzianka dla ciebie po 10 wizytach</div>
          </div>
        </div>
      </div>
      <div className={landingStyles.seventhSection}>
        <h3>Wszechstronna opieka Twojej skóry</h3>
        <p>
          Jeśli zastanawiasz się nad tym, który zabieg będzie dla Ciebie
          najbardziej odpowiedni i wciąż nie wiesz... <br />
          Nie martw się ! Zapraszam Cię na konsultację, gdzie dowiesz się jak
          powinnaś dbać o skórę zarówno w domu jak i gabinecie.
        </p>
        <div className={landingStyles.procedures}>
          {procedures.map((procedure, id) => (
            <div key={`procedure-${id}`}>
              <img src={procedure.image} />
              <h4>{procedure.title}</h4>
              <p>{procedure.text}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={landingStyles.eigthSection}>
        <h1>Opinie naszych klientek</h1>
        <CarouselOpinions
          screenSize={screenSize}
          width={size.width}
          setSelectedOpinion={setSelectedOpinion}
          selectedOpinion={selectedOpinion}
        />
      </div>
    </div>
  );
};

export default LandingPage;
