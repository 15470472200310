import React from "react";
import locationStyles from "../styles/locationStyles.module.css";

const Location = () => {
  const location = require("../images/location.png");
  return (
    <div className={locationStyles.body}>
      <h5>nasza lokalizacja</h5>
      <h1>Odwiedź Lumio skin w warszawie</h1>
      <div className={locationStyles.location}>
        <div>
          <img src={location} />
          <div>
            <h3>Wola</h3>
            <span>- pod adresem -</span>
            <p>Aleja Solidarności 173</p>
          </div>
        </div>
      </div>
      <div className={locationStyles.bottom} />
      <div />
    </div>
  );
};

export default Location;
