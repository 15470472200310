import React from "react";
import landingStyles from "../styles/landingStyles.module.css";

const CarouselOpinions = ({
  screenSize,
  width,
  setSelectedOpinion,
  selectedOpinion,
}) => {
  const opinions = [
    {
      text: "Jako stała i niezmiennie zadowolona klientka pani Magdy polecam każdemu oddać się bez wahania w jej ręce! Swego czasu zmagałam się z poważnymi problemami dermatologicznymi; z prowadzeniem pani Magdy i po proponowanych przez nią zabiegach udało się wyprowadzić moją cerę do naprawdę rewelacyjnego stanu. Po ostatniej wizycie dodatkowo serdecznie polecam zabieg Biorepeel - skóra jest gładziutka, napięta, odżywiona, rozjaśniona, bez śladu stanów zapalnych i zaczerwienień - muszę się powstrzymywać, by nie pchać się ciągle z łapami do twarzy, bo cera aż mruczy z zadowolenia :D Jestem zachwycona!",
      name: "Anna Z.",
      date: "SIE 25, 2022",
    },
    {
      text: "Dokładnie to, czego szukałam! Dokładna analiza potrzeb skóry, plan zabiegów gabinetowych i pielęgnacji domowej, wszystko wyjaśnione i uzasadnione. Pani Magda jest bardzo kontaktowa, ma ogromną wiedzę merytoryczną i potrafi budować atmosferę spokoju i relaksu.",
      name: "Marta C.",
      date: "STY 7, 2022",
    },
    {
      text: "Odwiedziłam gabinet Pani Magdy z zamiarem zadbania o moją problematyczna skórę i profilaktykę zmarszczek. Po wstępnym wywiadze został mi przedstawiony i wyjaśniony plan zabiegów. Czułam się zaopiekowana i doinformowana. Widać, że Pani Magda ma ogromną wiedzę i lubi się nią dzielić, co bardzo doceniam. Sam zabieg mezoterapii był bardzo komfortowy i już po jednym widzę różnicę. Cena nie była wygórowana, nie zostałam też 'naciągnięta' na zabiegi, których nie potrzebuję. Gdy zapytałam, czy moja cera skorzystałaby z innego, o wiele droższego zabiegu, Pani Magda szczerze odpowiedziała, że to zabieg dla cery dojrzałej i dla mojej to nie będzie miało większego sensu. Wszystko przebiegło w bardzo miłej atmosferze. Na pewno będę kontynuować zaproponowaną serię zabiegów u Pani Magdy.",
      name: "Justyna M.",
      date: "GRU 13, 2021",
    },
    {
      text: "Pani Magda to po prostu cudo! Wszystko bardzo dobrze wyjaśnia, oferuje niesamowity zakres usług! Stan mojej skory już po pierwszych kilku zabiegach polepszył się aż nie do poznania! Całą rodziną tutaj chodzimy i jeszcze żadnego razu nie pożałowałyśmy! Polecam od całego serca.",
      name: "Sonya L.",
      date: "LUT 10, 2023",
    },
    {
      text: "Przyjemne, relaksujące i całkowicie bezbolesne zabiegi na brzuch i szyję! Bardzo polecam Panią Magdę, wszystko po kolei, na spokojnie wytłumaczy, profesjonalnie doradzi co się da zrobić a czego unikać przy np. tak jak u mnie skórze naczynkowej. Plus miła atmosfera, odprężające łóżko z masażem i konkurencyjne ceny. Za tydzień jestem umówiona na kolejną wizytę. Pozdrawiam : )",
      name: "Ewa P.",
      date: "LUT 14, 2024",
    },
    {
      text: "Wizyta przebiegła w przesympatycznej atmosferze, dowiedziałam się wielu ciekawych rzeczy zarówno o przeprowadzanych zabiegach, jak i codziennej pielęgnacji. Efekty były widoczne już w trakcie wizyty, a ja wyszłam zrelaksowana i w super humorze. Serdecznie polecam!",
      name: "Ewa K.",
      date: "WRZ 21, 2023",
    },
  ];
  return (
    <div className={landingStyles.opinionsCarousel}>
      <div style={{ zIndex: 2 }}>
        <span
          onClick={() =>
            selectedOpinion > 0 ? setSelectedOpinion(selectedOpinion - 1) : {}
          }
          style={{ opacity: selectedOpinion === 0 ? 0.5 : 1 }}
          className="material-symbols-outlined"
        >
          arrow_back_ios
        </span>
        <span
          onClick={() =>
            selectedOpinion < 6 - (screenSize === 0 ? 1 : screenSize)
              ? setSelectedOpinion(selectedOpinion + 1)
              : {}
          }
          style={{ opacity: selectedOpinion === 6 - (screenSize === 0 ? 1 : screenSize) ? 0.5 : 1 }}
          className="material-symbols-outlined"
        >
          arrow_forward_ios
        </span>
      </div>
      <div
        style={{
          overflow: "hidden",
        }}
      >
        <div
          className={landingStyles.opinions}
          style={{
            width: `${600 / (screenSize === 0 ? 1 : screenSize)}%`,
            transform: `translateX(-${
              (width * selectedOpinion) / (screenSize === 0 ? 1 : screenSize) -
              165 *
                ((screenSize === 0
                  ? 0.43
                  : screenSize === 1
                  ? 1
                  : screenSize === 2
                  ? 0.25
                  : 0.11) *
                  (screenSize === 0 ? 1 : screenSize)) *
                selectedOpinion
            }px)`,
          }}
        >
          {opinions.map((opinion, id) => (
            <div
              key={`opinion-${id}`}
              style={{
                width: `calc(${100 / screenSize}% - ${20 * screenSize}px)`,
                margin: "0 10px",
              }}
            >
              <div>
                {[1, 2, 3, 4, 5].map((key) => (
                  <span key={`opinion-${id}-${key}`} className="material-icons">
                    grade
                  </span>
                ))}
              </div>
              <p>{opinion.text}</p>
              <h4>{opinion.name}</h4>
              <h5>{opinion.date}</h5>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CarouselOpinions;
